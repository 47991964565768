// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";

const MobileRoutes: IRoute[] = [
    {
        path: "/:lang/home",
        Component: lazy(() => import("@src/Pages/International/Mobile/Home")),
    },
    {
        path: "/:lang/qr-order",
        Component: lazy(() => import("@src/Pages/International/Mobile/QROrder")),
    },
    {
        path: "/:lang/reservas",
        Component: lazy(() => import("@src/Pages/International/Mobile/Reservas")),
    },
    {
        path: "/:lang/carta-digital",
        Component: lazy(() => import("@src/Pages/International/Mobile/CartaDigital")),
    },
    {
        path: "/:lang/tpv",
        Component: lazy(() => import("@src/Pages/International/Mobile/Tpv")),
    },
    {
        path: "/:lang/back-office",
        Component: lazy(() => import("@src/Pages/International/Mobile/BackOffice")),
    },
    {
        path: "/:lang/member",
        Component: lazy(() => import("@src/Pages/International/Mobile/Member")),
    },
    {
        path: "/:lang/marketing",
        Component: lazy(() => import("@src/Pages/International/Mobile/Marketing")),
    },
    // {
    //     path: "/:lang/tarifas",
    //     Component: lazy(() => import("@src/Pages/International/PC/Tarifas")),
    // },
    {
        path: "/:lang/contact",
        Component: lazy(() => import("@src/Pages/International/Mobile/Contact")),
    },
    {
        path: "/:lang/rs-insight-app",
        Component: lazy(() => import("@src/Pages/International/Mobile/RsInsightApp")),
    },
    {
        path: "/:lang/kds",
        Component: lazy(() => import("@src/Pages/International/Mobile/Kds")),
    },
    {
        path: "/:lang/quioscos",
        Component: lazy(() => import("@src/Pages/International/Mobile/Quioscos")),
    },
    {
        path: "/:lang/resto-para",
        Component: lazy(() => import("@src/Pages/International/Mobile/RestoPara")),
    },
    {
        path: "/:lang/sobre-nosotros",
        Component: lazy(() => import("@src/Pages/International/Mobile/SobreNosotros")),
    },
    {
        path: "/:lang/gestiony-control",
        Component: lazy(() => import("@src/Pages/International/Mobile/GestionyControl")),
    },
    {
        path: "/:lang/delivery-takeout",
        Component: lazy(() => import("@src/Pages/International/Mobile/DeliveryUnionTakeout")),
    },
    {
        path: "/:lang/electronic-menu",
        Component: lazy(() => import("@src/Pages/International/Mobile/ElectricMenu")),
    },
    {
        path: "/:lang/download-center",
        Component: lazy(() => import("@src/Pages/International/Mobile/DownLoadCenter")),
    },
    {
        path: "/:lang/es-legal-notice",
        Component: lazy(() => import("@src/Pages/International/Mobile/EsLegalCookiesPrivate/LegalNotice")),
    },
    {
        path: "/:lang/es-cookie",
        Component: lazy(() => import("@src/Pages/International/Mobile/EsLegalCookiesPrivate/Cookies")),
    },
    {
        path: "/:lang/es-private",
        Component: lazy(() => import("@src/Pages/International/Mobile/EsLegalCookiesPrivate/Private")),
    },
    {
        path: "/:lang/en-legal-notice",
        Component: lazy(() => import("@src/Pages/International/Mobile/EnLegalCookiesPrivate/LegalNotice")),
    },
    {
        path: "/:lang/en-cookie",
        Component: lazy(() => import("@src/Pages/International/Mobile/EnLegalCookiesPrivate/Cookies")),
    },
    {
        path: "/:lang/en-private",
        Component: lazy(() => import("@src/Pages/International/Mobile/EnLegalCookiesPrivate/Private")),
    },
];

export default MobileRoutes;
