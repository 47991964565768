enum RestoParaEnum {
    restoParaIntrosRow1 = "restoPara.restoParaIntrosRow1",

    restoParaCateGoryName1 = "restoPara.CateGoryName1",
    restoParaCateGoryName2 = "restoPara.CateGoryName2",
    restoParaCateGoryName3 = "restoPara.CateGoryName3",
    restoParaCateGoryName4 = "restoPara.CateGoryName4",
    restoParaCateGoryName5 = "restoPara.CateGoryName5",
    restoParaCateGoryName6 = "restoPara.CateGoryName6",
    restoParaCateGoryName7 = "restoPara.CateGoryName7",
    restoParaCateGoryName8 = "restoPara.CateGoryName8",
    restoParaCateGoryName9 = "restoPara.CateGoryName9",
    restoParaCateGoryName10 = "restoPara.CateGoryName10",

    restoParaIntrosRow2 = "restoPara.restoParaIntrosRow2",


    restoParaBannerHeaderItalicTitle = "restoPara.bannerHeaderItalicTitle",
    restoParaBannerHeadernNormalTitle = "restoPara.bannerHeadernNormalTitle",


    restoParaImgBottomRow1 = "restoPara.imgBottomRow1",

    restoParaSceneNumber1Title = "restoPara.sceneNumber1Title",
    restoParaSceneNumber1List1 = "restoPara.sceneNumber1List1",
    restoParaSceneNumber1List2 = "restoPara.sceneNumber1List2",
    restoParaSceneNumber1List3 = "restoPara.sceneNumber1List3",
    restoParaSceneNumber1List4 = "restoPara.sceneNumber1List4",


    restoParaSceneNumber2Title = "restoPara.sceneNumber2Title",
    restoParaSceneNumber2List1 = "restoPara.sceneNumber2List1",
    restoParaSceneNumber2List2 = "restoPara.sceneNumber2List2",


    restoParaSceneNumber3Title = "restoPara.sceneNumber3Title",
    restoParaSceneNumber3List1 = "restoPara.sceneNumber3List1",
    restoParaSceneNumber3List2 = "restoPara.sceneNumber3List2",
    restoParaSceneNumber3List3 = "restoPara.sceneNumber3List3",


    restoParaSceneNumber4Title = "restoPara.sceneNumber4Title",
    restoParaSceneNumber4List1 = "restoPara.sceneNumber4List1",


    restoParaSceneNumber5Title = "restoPara.sceneNumber5Title",
    restoParaSceneNumber5List1 = "restoPara.sceneNumber5List1",
    restoParaSceneNumber5List2 = "restoPara.sceneNumber5List2",
    restoParaSceneNumber5List3 = "restoPara.sceneNumber5List3",


    restoParaSceneNumber6Title = "restoPara.sceneNumber6Title",
    restoParaSceneNumber6List1 = "restoPara.sceneNumber6List1",
    restoParaSceneNumber6List2 = "restoPara.sceneNumber6List2",
    restoParaSceneNumber6List3 = "restoPara.sceneNumber6List3",

    restoParaSceneNumber7Title = "restoPara.sceneNumber7Title",
    restoParaSceneNumber7List1 = "restoPara.sceneNumber7List1",
    restoParaSceneNumber7List2 = "restoPara.sceneNumber7List2",
    restoParaSceneNumber7List3 = "restoPara.sceneNumber7List3",
    restoParaSceneNumber7List4 = "restoPara.sceneNumber7List4",


    restoParaSceneNumber8Title = "restoPara.sceneNumber8Title",
    restoParaSceneNumber8List1 = "restoPara.sceneNumber8List1",
    restoParaSceneNumber8List2 = "restoPara.sceneNumber8List2",
}

export default RestoParaEnum