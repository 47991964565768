enum MarketingEnum {
    marketingBannerHeaderTitleSpan1 = "marketing.bannerHeaderTitleSpan1",
    marketingBannerHeaderTitleItalic = "marketing.bannerHeaderTitleItalic",
    marketingBannerHeaderTitleSpan2 = "marketing.bannerHeaderTitleSpan2",
    marketingBannerHeaderDesc = "marketing.bannerHeaderDesc",

    marketingHalfContainer1TextTitle = "marketing.halfContainer1TextTitle",
    marketingHalfContainer1TextDesc = "marketing.halfContainer1TextDesc",

    marketingBackGroundUrlTextTitle = "marketing.backGroundUrlTextTitle",

    marketingHalfContainer2TextTitle = "marketing.halfContainer2TextTitle",
    marketingHalfContainer2TextDesc = "marketing.halfContainer2TextDesc",

    // 
    marketingCampanasTitle = "marketing.campanasTitle",
    marketingCampanasIntro1 = "marketing.campanasIntro1",
    marketingCampanasIntro2 = "marketing.campanasIntro2",
    marketingCampanasIntro3 = "marketing.campanasIntro3",
    marketingCampanasIntro4 = "marketing.campanasIntro4",
    marketingCampanasIntro5 = "marketing.campanasIntro5",

    marketingHalfContainer3TextTitle = "marketing.halfContainer3TextTitle",
    marketingHalfContainer3TextDesc = "marketing.halfContainer3TextDesc",

    marketingHalfContainer4TextTitle1 = "marketing.halfContainer4TextTitle1",
    marketingHalfContainer4TextDesc1 = "marketing.halfContainer4TextDesc1",
    marketingHalfContainer4TextTitle2 = "marketing.halfContainer4TextTitle2",
    marketingHalfContainer4TextDesc2 = "marketing.halfContainer4TextDesc2",
    marketingHalfContainer4TextTitle3 = "marketing.halfContainer4TextTitle3",
    marketingHalfContainer4TextDesc3 = "marketing.halfContainer4TextDesc3",
    marketingHalfContainer4TextTitle4 = "marketing.halfContainer4TextTitle4",
    marketingHalfContainer4TextDesc4 = "marketing.halfContainer4TextDesc4",
    marketingHalfContainer4TextTitle5 = "marketing.halfContainer4TextTitle5",
    marketingHalfContainer4TextDesc5 = "marketing.halfContainer4TextDesc5",

    marketingBglTitle = "marketing.bglTitle",

}

export default MarketingEnum