enum SobreNosotrosEnum {
    sobreNosotrosBannerHeaderTitleSpan = "sobreNosotros.bannerHeaderTitleSpan",
    sobreNosotrosBannerHeaderDesc = "sobreNosotros.bannerHeaderDesc",

    sobreNosotrosBackgroundUrlCustomContentTitle = "sobreNosotros.backgroundUrlCustomContentTitle",
    sobreNosotrosBackgroundUrlCustomContentDesc = "sobreNosotros.backgroundUrlCustomContentDesc",

    sobreNosotrosHalfContainer1TextTitle = "sobreNosotros.halfContainer1TextTitle",
    sobreNosotrosHalfContainer1TextDesc = "sobreNosotros.halfContainer1TextDesc",
    sobreNosotrosHalfContainer1TextBtnText = "sobreNosotros.halfContainer1TextBtnText",


    sobreNosotrosHalfContainer2TextTitle = "sobreNosotros.halfContainer2TextTitle",
    sobreNosotrosHalfContainer2TextDesc = "sobreNosotros.halfContainer2TextDesc",

    sobreNosotrosBackgroundUrl2Title = "sobreNosotros.backgroundUrl2Title",

    sobreNosotrosBackgroundUrlItalicTitle = "sobreNosotros.backgroundUrlItalicTitle",
    sobreNosotrosBackgroundUrlNormalTitle = "sobreNosotros.backgroundUrlNormalTitle",
    sobreNosotrosBackgroundUrlDesc = "sobreNosotros.backgroundUrlDesc",

    // mobile
    sobreNosotrosSoportede = "sobreNosotros.sobreNosotros.soporteDe",
    sobreNosotrosServicioGlobal = "sobreNosotros.servicioGlobal",
}
export default SobreNosotrosEnum