import BackOfficeEnum from '@src/Locales/Constants/International/BackOfficeEnum';
import CartaDigitalEnum from '@src/Locales/Constants/International/CartaDigitalEnum';
import ContactEnum from '@src/Locales/Constants/International/ContactEnum';
import HomeEnum from '@src/Locales/Constants/International/HomeEnum'
import InterQrOrderEnum from '@src/Locales/Constants/International/InterQrOrderEnum';
import KdsEnum from '@src/Locales/Constants/International/KdsEnum';
import MarketingEnum from '@src/Locales/Constants/International/MarketingEnum';
import MemberEnum from '@src/Locales/Constants/International/MemberEnum';
import QuioscosEnum from '@src/Locales/Constants/International/QuioscosEnum';
import ReservasEnum from '@src/Locales/Constants/International/ReservasEnum';
import RestoParaEnum from '@src/Locales/Constants/International/RestoParaEnum';
import RsInsightAppEnum from '@src/Locales/Constants/International/RsSinsightAppEnum';
import SobreNosotrosEnum from '@src/Locales/Constants/International/SobreNosotrosEnum';
import TarifasEnum from '@src/Locales/Constants/International/TarifasEnum';
import TPVEnum from '@src/Locales/Constants/International/TPVEnum';
import GestionyControlEnum from '@src/Locales/Constants/International/GestionyControlEnum'
import DownLoadCenterEnum from '@src/Locales/Constants/International/DownLoadCenterEnum'
import DeliveryUnionTakeAwayEnum from '@src/Locales/Constants/International/DeliveryUnionTakeAwayEnum'
import ElectronicMenuEnum from '@src/Locales/Constants/International/ElectricMenuEnum'
import GlobalServiceEnum from '@src/Locales/Constants/International/GlobalServiceEnum';


enum CommonInternationEnum {
    LOADING = "common.loading",
    // 图片加载失败
    imageLoadingFailed = "common.imageLoadingFailed",
    success = "common.success",
    fail = "common.fail",
    formSubmitSuccessMsg = "common.formSubmitSuccessMsg",
    requiredFieldTips = "common.requiredFieldTips"
}

export const InternationalEnum = {
    ...CommonInternationEnum,
    ...BackOfficeEnum,
    ...CartaDigitalEnum,
    ...ContactEnum,
    ...HomeEnum,
    ...InterQrOrderEnum,
    ...KdsEnum,
    ...MarketingEnum,
    ...MemberEnum,
    ...QuioscosEnum,
    ...ReservasEnum,
    ...RestoParaEnum,
    ...RsInsightAppEnum,
    ...SobreNosotrosEnum,
    ...TarifasEnum,
    ...TPVEnum,
    ...GestionyControlEnum,
    ...DownLoadCenterEnum,
    ...DeliveryUnionTakeAwayEnum,
    ...ElectronicMenuEnum,
    ...GlobalServiceEnum
};
export default InternationalEnum;