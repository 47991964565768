import { useTranslation } from "react-i18next";
import styles from "./index.module.less";
import { useNavigate, useParams } from "react-router-dom";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import useImagesLanguage from "@src/Hooks/International/hooks/useImageLanuage";
import SwitchLanguage from "@src/Components/InternationalLayouts/MobileLayouts/Header/SwitchLanguage";
import NavList from "./NavList";
import { ReactComponent as Menu } from "@src/assets/international/svgs/menu.svg";
import { useState, useImperativeHandle, forwardRef, Dispatch, SetStateAction } from "react";
import { CloseOutlined } from "@ant-design/icons";

interface IMobileHeaderProps {
  menuCollapse: boolean;
  setMenuCollapse: Dispatch<SetStateAction<boolean>>;
}

const MobileHeader = (props: IMobileHeaderProps) => {
  const { menuCollapse, setMenuCollapse } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { home } = useImagesLanguage();
  const { lang } = useParams();

  const renderContactButton = () => {
    return (
      <div
        className={`${styles.PCNowStart} cursor-pointer`}
        onClick={() => {
          navigate(`/${lang}/contact`);
          setMenuCollapse(false);
        }}
      >
        {t(InternationalEnum.applyLinkBtn, { ns: "InternationalWebsite" })}
      </div>
    );
  };

  return (
    <div className={`${styles.PCHeaderWrap}`}>
      <header className={`${styles.PCHeader} flex items-center justify-between`}>
        <div
          style={{
            background: `url(${home[0].src}) center center/ contain no-repeat`,
            width: 128,
            height: 24,
            minWidth: 128,
          }}
          onClick={() => {
            navigate("/");
            setMenuCollapse(false);
          }}
          className={`${styles.PCLogo}`}
        />

        {menuCollapse ? (
          <div className="block" onClick={() => setMenuCollapse(false)}>
            <CloseOutlined className="text-[#4340F4]" />
          </div>
        ) : (
          <Menu onClick={() => setMenuCollapse(true)} />
        )}
      </header>

      {menuCollapse && (
        <section className={`${styles.PCMenuSection}`}>
          <NavList setMenuCollapse={setMenuCollapse} />
          <SwitchLanguage setMenuCollapse={setMenuCollapse} />
          {renderContactButton()}
        </section>
      )}
    </div>
  );
};
export default MobileHeader;
