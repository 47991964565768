import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import i18n from "@src/Basic/Language/i18n";
import useNavList, { INav, ISubContent } from "@src/Hooks/International/hooks/useNavList";
import styles from "./index.module.less";
import { ReactComponent as Chevron } from "@src/assets/international/svgs/chevron.svg";

interface INavListProps {
  setMenuCollapse: Dispatch<SetStateAction<boolean>>;
}

const NavList = (props: INavListProps) => {
  const { setMenuCollapse } = props;

  const { navList } = useNavList();
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const { lang } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  useEffect(() => {
    setData(navList);
  }, [navList]);

  const handleChevron = (key: string | undefined, chevron: string) => {
    const newData = data.map((item) => {
      if (item.chevron && item.key === key) {
        item.chevron = chevron;
      }
      return item;
    });
    setData(newData);
  };

  const handleNavigate = (item: any) => {
    navigate(`/${lang}${item.path}`);
    setMenuCollapse(false); // 关闭菜单
  };

  const handleTabNav = (item: INav) => {
    if (_.isEmpty(item)) return;

    const { path, key, content } = item;
    handleChevron(key, item.chevron === "up" ? "down" : "up");
    if (!content?.length) {
      navigate(`/${lang || i18Language}${path}`);
      setMenuCollapse(false); // 关闭菜单
    }
  };

  const renderContentListItem = (o: INav, item: INav) => {
    return (
      <div className={`flex items-center w-full`}>
        {o.title && !o.showDisabled && (
          <nav
            className={`
            ${styles.PCNavTitle} 
            cursor-pointer block w-full`}
            onClick={(e) => {
              e.stopPropagation();
              o.path && handleNavigate(o);
              o.path && handleChevron(item.key, "down");
            }}
          >
            {o.title}
          </nav>
        )}

        {o.title && o.showDisabled && (
          <nav className={`${styles.PCNavTitle} ${styles.PCNavTitleDisabled} cursor-pointer`}>
            {o.title}
          </nav>
        )}
      </div>
    );
  };

  const renderIconTitle = (o: INav) => {
    return (
      <div className={`flex items-center w-full ${styles.PCIconTitleWrap}`}>
        <div className="flex items-center">{o.titleIcon}</div>

        {o.title && !o.showDisabled && (
          <nav
            className={`
            ${styles.PCNavTitle} 
            cursor-pointer block w-full`}
          >
            {o.title}
          </nav>
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.PCNavList} flex flex-col`}>
      {data.map((item: INav) => {
        return (
          <div
            key={item.key}
            onClick={(e) => {
              handleTabNav(item);
            }}
          >
            <div
              className={`${styles.PCTabTitle} cursor-pointer flex justify-between items-center`}
            >
              <div>{item.title}</div>
              {item.chevron && (
                <Chevron
                  className={`${
                    item.chevron === "up" ? styles.PCChevronUp : styles.PCChevronDown
                  } `}
                />
              )}
            </div>

            {item.chevron === "up" && (
              <div
                className={`flex flex-col`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {item?.content?.map((navContent: ISubContent, index: number, origin) => {
                  return (
                    <div
                      className={`${styles.PCNavContent} ${
                        item.chevron === "up" ? styles.PCNavContentActive : ""
                      }`}
                      key={index}
                    >
                      {renderIconTitle(navContent)}

                      <div className={`${styles.PCContentListWrap}`}>
                        {navContent?.list?.map((nav: INav, idx: number) => {
                          return (
                            <div className={`flex justify-between`}>
                              {renderContentListItem(nav, item)}
                              {!nav?.showDisabled && <div>&gt;</div>}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NavList;
